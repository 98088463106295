import "@fontsource/kanit/300.css"
import "@fontsource/kanit/400.css"
import "@fontsource/kanit/700.css"
import "./static/assets/css/slick.min.css"
import "./static/assets/css/slick-theme.min.css"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
