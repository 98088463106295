// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-corporate-governance-anti-corruption-policy-js": () => import("./../../../src/pages/corporate-governance/anti-corruption-policy.js" /* webpackChunkName: "component---src-pages-corporate-governance-anti-corruption-policy-js" */),
  "component---src-pages-corporate-governance-business-ethics-policy-js": () => import("./../../../src/pages/corporate-governance/business-ethics-policy.js" /* webpackChunkName: "component---src-pages-corporate-governance-business-ethics-policy-js" */),
  "component---src-pages-corporate-governance-environmental-policy-js": () => import("./../../../src/pages/corporate-governance/environmental-policy.js" /* webpackChunkName: "component---src-pages-corporate-governance-environmental-policy-js" */),
  "component---src-pages-corporate-governance-esg-policy-js": () => import("./../../../src/pages/corporate-governance/esg-policy.js" /* webpackChunkName: "component---src-pages-corporate-governance-esg-policy-js" */),
  "component---src-pages-corporate-governance-governance-policy-js": () => import("./../../../src/pages/corporate-governance/governance-policy.js" /* webpackChunkName: "component---src-pages-corporate-governance-governance-policy-js" */),
  "component---src-pages-corporate-governance-health-policy-js": () => import("./../../../src/pages/corporate-governance/health-policy.js" /* webpackChunkName: "component---src-pages-corporate-governance-health-policy-js" */),
  "component---src-pages-corporate-governance-js": () => import("./../../../src/pages/corporate-governance.js" /* webpackChunkName: "component---src-pages-corporate-governance-js" */),
  "component---src-pages-corporate-governance-receive-a-complaint-js": () => import("./../../../src/pages/corporate-governance/receive-a-complaint.js" /* webpackChunkName: "component---src-pages-corporate-governance-receive-a-complaint-js" */),
  "component---src-pages-distributor-js": () => import("./../../../src/pages/distributor.js" /* webpackChunkName: "component---src-pages-distributor-js" */),
  "component---src-pages-environmental-care-js": () => import("./../../../src/pages/environmental-care.js" /* webpackChunkName: "component---src-pages-environmental-care-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-organization-management-board-js": () => import("./../../../src/pages/organization-management/board.js" /* webpackChunkName: "component---src-pages-organization-management-board-js" */),
  "component---src-pages-organization-management-executive-js": () => import("./../../../src/pages/organization-management/executive.js" /* webpackChunkName: "component---src-pages-organization-management-executive-js" */),
  "component---src-pages-organization-management-organization-structure-js": () => import("./../../../src/pages/organization-management/organization-structure.js" /* webpackChunkName: "component---src-pages-organization-management-organization-structure-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-standard-certification-js": () => import("./../../../src/pages/standard-certification.js" /* webpackChunkName: "component---src-pages-standard-certification-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */),
  "component---src-templates-jobs-single-js": () => import("./../../../src/templates/jobs-single.js" /* webpackChunkName: "component---src-templates-jobs-single-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/news-category.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-products-single-js": () => import("./../../../src/templates/products-single.js" /* webpackChunkName: "component---src-templates-products-single-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-projects-single-js": () => import("./../../../src/templates/projects-single.js" /* webpackChunkName: "component---src-templates-projects-single-js" */)
}

